<script setup>
</script>

<template>
  <main>    
    <div class="md:container md:mx-auto px-2 sm:px-6 lg:px-8">
    404
    </div>
  </main>
</template>
