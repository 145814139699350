<script setup>
import NavBar from "../components/NavBar.vue";
import NewsDetail from "../components/NewsDetail.vue";

import { ref, onMounted } from 'vue';
import { useRoute,  useRouter } from 'vue-router';

const newsDetail = ref(null);
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await loadNewsDetail();
});

async function loadNewsDetail() {
  // Simulez le chargement de données depuis un fichier JSON statique ou une API
  const response = await fetch('/news.json');
  const news = await response.json();
  const newsId = route.params.id;
  const foundNews = news.find(n => n.ID === newsId);
  if (foundNews) {
    newsDetail.value = foundNews;
  } else {
    console.error('News not found');
    router.push({ path: '/'})
  }
}

</script>

<template>
  <main>
    <section class="bg-white ">
            <div class="container max-w-6xl mx-auto m-8">
                <h1 class="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
                    Conseil des parents
                </h1>
                <h2 class="w-full my-2 text-xl font-bold leading-tight text-center text-gray-800">Cercle
                    scolaire de Bois-d’Amont et Ferpicloz</h2>
              </div>
      </section>
  <NavBar />
  <NewsDetail :news="newsDetail" />
  </main>
</template>
