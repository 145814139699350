<script setup>
import { ref } from 'vue';
import axios from 'axios'; 

const isSubmitted = ref(false);
// Les données du formulaire et les erreurs
const form = ref({
  name: '',
  email: '',
  message: ''
});
const errors = ref({});

// Fonction de validation de l'e-mail
const isValidEmail = (email) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(validRegex)
};



// Valider les champs du formulaire
const validateForm = () => {
  errors.value = {}; // Réinitialiser les messages d'erreur
  let valid = true;

  if (!form.value.name) {
    errors.value.name = 'Veuillez entrer un nom.';
    valid = false;
  }
  if (!form.value.email || !isValidEmail(form.value.email)) {
    errors.value.email = 'Une adresse e-mail est nécessaire.';
    valid = false;
  }
  if (!form.value.message) {
    errors.value.message = 'Veuillez entrer votre message.';
    valid = false;
  }

  return valid;
};

// Fonction pour gérer la soumission du formulaire
const handleSubmit = async () => {
    console.log(validateForm())
    console.log(errors)
  if (validateForm()) {
    try {
      const response = await axios.post('/formulaires/contact/', form.value);
      console.log(response.data);
      isSubmitted.value = true;
      // Réinitialiser le formulaire ou rediriger l'utilisateur après succès
    } catch (error) {
      console.error(error);
    }
  }
};
</script>
<template>
    <section class="bg-white border-b py-8 bg-zinc-200 ">
            <div class="container max-w-6xl mx-auto m-8 ">
                <h1 class="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800" id="contact">
                    Contact
                </h1>
                <div class="w-full mb-4">
                    <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <div class="flex flex-wrap" v-if="!isSubmitted">
                    <div class=" p-6 w-full">
                        <p class="text-gray-600 mb-8">
                            Vous avez une question, une remarque ou une idée ? Contactez-nous via le formulaire ou l'adresse <a href="mailto:contact@conseil-des-parents.ch">contact@conseil-des-parents.ch</a>.
                        </p>
                        <form  @submit.prevent="handleSubmit">
                          
                        <label class="form-control flex  gap-2 mb-4">
                            <div class="label">
                                <span class="label-text">Nom</span>
                            </div>
                            <input type="text" id="name" v-model="form.name" placeholder="Nom Prénom" class="input input-bordered w-full "  required/>
                            <p v-if="errors.name"  class="text-right text-error">{{ errors.name }}</p>
                        </label>
                        <label class="form-control flex  gap-2 mb-4">
                            <div class="label">
                                <span class="label-text">E-mail</span>
                            </div>
                            <input  id="email" v-model="form.email" type="text" placeholder="mon.addresse@domaine.com" class="input input-bordered w-full"  required/>

                            <p v-if="errors.email" class="text-right text-error">{{ errors.email }}</p>
                        </label>
                        <label class="form-control flex  gap-2 mb-4">
                            <div class="label">
                                <span class="label-text">Message</span>
                            </div>
                            <textarea  id="message" v-model="form.message" placeholder="Votre question, idée, remarque" class="textarea text-base textarea-bordered textarea-sm w-full h-60" required></textarea>
                            <p v-if="errors.message" class="text-right text-error">{{ errors.message }}</p>
                        </label>

                        <button type="submit" class="px-4 py-2 text-sm font-bold  rounded text-white border-2 border-sky-900 bg-sky-900 hover:text-sky-900 hover:bg-slate-200">Envoyer</button>
                        
                        </form>
                    </div>
                </div>
                <div v-else class="flex flex-wrap">
                  <div role="alert" class="alert alert-success">
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span>Merci. Nous vous contacterons prochainement.</span>
                  </div>
                   
                </div>
            </div>
        </section>
</template>
<style scoped>
a:hover{
  text-decoration: underline;
}</style>