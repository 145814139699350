<script setup>
import { defineProps, defineAsyncComponent, computed, onMounted } from 'vue';

const props = defineProps({
  news: {
    type: Object,
    required: false, // Rendre non requis ou
    default: () => null, // fournir une valeur par défaut qui est null
    validator: (value) => value === null || typeof value === "object",
  },
});

onMounted(() => {
});
 

const DynamicComponent = computed(() => {
  return props.news.componentName
    ? defineAsyncComponent(() =>
        import(`../components/${props.news.componentName}.vue`)
      )
    : null; // Ou définit un composant par défaut ici
});
</script>
<template>
  <section class="bg-white border-b" v-if="props.news">
    <div class="container max-w-6xl mx-auto m-8">
      <h1 class="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
        {{ props.news.title }}
      </h1>
      <div class="flex flex-wrap flex-col-reverse sm:flex-row">
        <div class="w-full sm:w-1/3 p-6 mt-6">
          <img
            v-if="props.news.image"
            :src="props.news.image"
            alt="Image de la News"
          />
        </div>
        <div class="w-full sm:w-2/3 p-6 mt-6" v-if="props.news">
          <div class="flow-root max-w-3xl mx-auto">
            <div class="pb-5">
            <p class="text-lg font-bold text-gray-700 dark:text-gray-400 shrink-0 pb-5">
              {{ props.news["detail"] }}
            </p>
              <div class="text-lg font-normal text-gray-700 dark:text-gray-400 shrink-0" v-html="props.news.description"></div>
            </div>
            <p class="text-lg font-normal text-gray-500 dark:text-gray-400 shrink-0 text-right">
              <router-link to="/#activities" class="px-4 py-2 text-sm font-bold text-gray-500 text-right rounded hover:bg-sky-900 hover:text-white"> ← Retour à la page d'accueil</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <component :is="DynamicComponent"  v-if="DynamicComponent"/>
  </section>
</template>
