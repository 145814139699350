<script>

</script>
<template>
   
   <RouterView name="Navigation" class=""></RouterView>

<RouterView/>
</template>


<style>
 #app {
  background-color: #FFF;
 }
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
