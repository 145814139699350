<script setup>
import PartyIcon from "../components/icons/PartyIcon.vue";
import { ref, onMounted } from 'vue';

const newsList = ref([]);

onMounted(async () => {
  const response = await fetch('/news.json');
  newsList.value = await response.json();
});
</script>
<template>
    <section class="bg-white border-b">
            <div class="container max-w-6xl mx-auto m-8">
               
                <div class="flex flex-wrap flex-col-reverse sm:flex-row">
                    <div class="w-full sm:w-1/2 p-6 mt-6">
                       <PartyIcon/>
                      
                    </div>
                    <div class="w-full sm:w-1/2 p-6 mt-6">
                        <div class="align-middle">
                            <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3" id="activities">
                                Prochaines activités
                            </h3>
                            
                          <div class="flow-root max-w-3xl mx-auto mt-8  divide-y divide-gray-200 ">
                                <div  v-for="news in newsList" :key="news.ID" class="pb-3 pt-3">
                                  <h3 class="text-lg font-semibold text-gray-900 ">
                                    <router-link :to="`/news/${news.ID}-${news.url}`" class="hover:underline">{{ news.title }}</router-link>
                                  </h3>
                                  <p class=" text-sm font-normal text-gray-700  shrink-0 pb-4">
                                    {{ news['detail'] }}
                                  </p>
                                  <p class="text-lg font-normal text-gray-500   shrink-0 pb-4">
                                    {{ news['shortdescription'] }}
                                  </p>
                                  <p class="text-lg font-normal text-gray-500   shrink-0 text-right">
                                    <router-link :to="`/news/${news.ID}-${news.url}`"  class="px-4 py-2 text-sm font-bold text-gray-500  rounded hover:bg-sky-900 hover:text-white">{{ news['button'] }}</router-link>
                                  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>