<script setup>
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

const router = useRouter();

function scrollToAnchor(anchorId) {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
    } else {
        router.push({ path: '/', hash: `#${anchorId}` });
    }
  }

  onMounted(() => {
    const anchor = window.location.hash;
    const anchorWithoutHash = anchor.slice(1);
    if (anchorWithoutHash){
      scrollToAnchor(anchorWithoutHash)
    }

})
</script>
<template>  
    <div class="navbar  bg-sky-900  drop-shadow-sm">
  <div class="navbar-start"></div>
      <div class="navbar-center">
        <ul class="menu menu-horizontal px-2">
                <li><a @click.prevent="scrollToAnchor('activities')" class="inline-block py-2 px-4 ml-2 mr-2 text-white font-bold no-underline hover:bg-zinc-200 hover:text-black">Activités</a></li>
                <li><a @click.prevent="scrollToAnchor('about')" class="inline-block py-2 px-4 ml-2 mr-2 text-white font-bold no-underline hover:bg-zinc-200 hover:text-black">A propos</a></li>
                <li><a @click.prevent="scrollToAnchor('contact')" class="inline-block py-2 px-4 ml-2 mr-2 text-white font-bold no-underline hover:bg-zinc-200 hover:text-black">Contact</a></li>
        </ul>
      </div>
  <div class="navbar-end"></div>
    </div>
</template>